import { motion } from "framer-motion";
import * as TUI from "theme-ui";

export const MotionBox = motion(TUI.Box, {
  forwardMotionProps: true,
});
export const MotionGrid = motion(TUI.Grid, {
  forwardMotionProps: true,
});

export const springConfigs = {
  slow: {
    type: "spring",
    stiffness: 180,
    damping: 25,
  },
  gentle: {
    type: "spring",
    stiffness: 200,
    damping: 20,
  },
  lively: {
    type: "spring",
    damping: 15,
    stiffness: 100,
  },
};
