import * as Icons from "@/icons";
import { useSelect } from "downshift";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import React from "react";
import * as TUI from "theme-ui";
import { Button } from "../Button";
import { MotionBox } from "../motion";

const ANIMATION_DURATION = 0.25;

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  alternates: Array<{ hrefLang: string; href: string }>;
}

function LocaleSwitcher(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { alternates, sx, ...rest } = props;
  const { locale, push } = useRouter();

  const s = useSelect({
    id: "LocaleSwitcher",
    items: alternates,
    onSelectedItemChange: (s) => {
      if (s.selectedItem) {
        push(s.selectedItem.href, undefined, {
          locale: false,
        });
      }
    },
  });

  if (alternates.length <= 1) {
    return null;
  }

  return (
    <Root
      ref={ref}
      {...rest}
      sx={{ ...sx }}
      onMouseEnter={() => s.openMenu()}
      onMouseLeave={() => s.closeMenu()}
      onTouchStartCapture={() => s.toggleMenu()}
    >
      <Button
        {...s.getToggleButtonProps()}
        as="button"
        variant="naked"
        onClick={undefined}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          textTransform: "capitalize",
          color: "text",
          ":focus": { outline: "none" },
        }}
      >
        <TUI.Text
          variant="f5_mono"
          sx={{
            margin: "-20px",
            padding: "20px",
          }}
        >
          {locale}
        </TUI.Text>
        <TUI.Box
          sx={{
            position: "absolute",
            top: [18, 24, 30],
            transform: s.isOpen ? "rotate(0deg)" : "rotate(180deg)",
            transition: `transform ${ANIMATION_DURATION}s`,
          }}
        >
          {<Icons.ChevronUp24></Icons.ChevronUp24>}
        </TUI.Box>
      </Button>

      <TUI.Box
        {...s.getMenuProps()}
        sx={{
          position: "relative",
          width: "100%",
          ":focus": { outline: "none" },
        }}
      >
        <AnimatePresence>
          {s.isOpen && (
            <MotionBox
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: 5 }}
              exit={{ opacity: 0, y: 0 }}
              transition={{ type: "tween", duration: ANIMATION_DURATION }}
              sx={{
                position: "absolute",
                top: 12,
                left: 0,
                right: 0,
                mx: "-16px",
                padding: "4px 0 4px 0",
                bg: "white",
                boxShadow: "elevation2",
              }}
            >
              {alternates.map((alt, index) => (
                <Button
                  key={alt.hrefLang}
                  {...s.getItemProps({ item: alt, index })}
                  variant="naked"
                  className="gtm-LocaleSwitcher-item"
                  sx={{
                    textTransform: "capitalize",
                    color: index === s.highlightedIndex ? "accent" : "text",
                    bg: index === s.highlightedIndex ? "grayLight" : undefined,
                    width: "100%",
                    py: 1,
                    px: "16px",
                  }}
                >
                  <TUI.Text variant="f5_mono">{alt.hrefLang}</TUI.Text>
                </Button>
              ))}
            </MotionBox>
          )}
        </AnimatePresence>
      </TUI.Box>
    </Root>
  );
}

export default React.forwardRef(LocaleSwitcher);
