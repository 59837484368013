import { pageLinkProps } from "@/lib/pageLinkProps";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import * as TUI from "theme-ui";
import { contentPageLinkProps } from "../../lib/links";
import { Markdown } from "../Markdown";
import { globalNotification } from "./data";

const Root = TUI.Box;

interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  pageId?: string;
}

const localStorageKey = "global-notification";

function GlobalNotification(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { sx, pageId = "", ...rest } = props;
  const { locale } = useRouter();

  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    try {
      if (localStorage.getItem(localStorageKey) !== globalNotification?.notificationId) {
        setIsVisible(true);
      }
    } catch {
      /*
       * Whatever
       */
    }
  }, [setIsVisible]);

  if (!globalNotification) {
    return null;
  }

  if (
    globalNotification.ignoredPages.map((d) => d.id).includes(pageId) ||
    !globalNotification._allNotificationActiveLocales?.find((d) => d?.locale === locale)?.value
  ) {
    return null;
  }

  if (!isVisible) {
    return null;
  }

  const link = globalNotification.notificationLink;
  const title = globalNotification._allNotificationTitleLocales?.find((x) => x?.locale === locale);
  const content = globalNotification._allNotificationContentLocales?.find((x) => x?.locale === locale);

  const dismiss = () => {
    setIsVisible(false);

    try {
      localStorage.setItem(localStorageKey, globalNotification?.notificationId!);
    } catch {
      /*
       * Whatever
       */
    }
  };

  return (
    <TUI.ThemeProvider theme={{ colors: { primary: "red" } }}>
      <Root
        ref={ref}
        sx={{
          bg: "white",
          width: "100%",
          boxShadow: "elevation2",
          mx: "auto",
          top: 4,
          zIndex: 999,
          maxWidth: "96rem",
          border: "2px solid currentColor",
          borderColor: "red",
          px: 4,
          py: 3,
          ...sx,
          display: "flex",
          "@media print": {
            display: "none",
          },
        }}
        {...rest}
      >
        <div style={{ flexGrow: 1 }}>
          {link ? (
            <Link
              {...(() => {
                switch (link.__typename) {
                  case "ContentPageRecord": {
                    return contentPageLinkProps(locale, link);
                  }
                  case "KvPostRecord": {
                    return pageLinkProps(locale, "KvPost", link.id);
                  }
                }
              })()}
              passHref
              legacyBehavior
            >
              <TUI.Link variant="default" sx={{ textDecoration: "none" }}>
                <TUI.Heading
                  as="h3"
                  variant="f5_bold"
                  sx={{
                    color: "red",
                    position: "sticky",
                    top: 0,
                    bg: "white",
                    m: 0,
                    "&:hover": {
                      opacity: 0.6,
                    },
                  }}
                >
                  {title?.value}
                </TUI.Heading>
              </TUI.Link>
            </Link>
          ) : (
            <TUI.Heading as="h3" variant="f5_bold" sx={{ position: "sticky", top: 0, bg: "white", py: 2 }}>
              {title?.value}
            </TUI.Heading>
          )}

          {content?.value && (
            <TUI.Text variant="f6">
              <Markdown>{content.value}</Markdown>
            </TUI.Text>
          )}
        </div>
        <TUI.Button
          variant="ghost"
          sx={{ border: "none", ml: "auto", p: 2, m: -2, mr: -3 }}
          onClick={() => {
            dismiss();
          }}
        >
          <TUI.Image src="/icons/close.svg" />
        </TUI.Button>
      </Root>
    </TUI.ThemeProvider>
  );
}

export default React.forwardRef(GlobalNotification);
